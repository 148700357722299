import styled from 'styled-components';

import { rem } from '@savgroup-front-common/core/src/helpers';

export const $StepProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  justify-content: center;
  padding: 0 100px;
`;

export const $StepProgressItem = styled.div`
  display: flex;
  flex-direction: column;
  width: ${rem(90)};
  justify-content: center;
  align-items: center;
`;

export const $StepProgressCircle = styled.div<{ $active?: boolean }>`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${({ theme, $active = false }) =>
    $active
      ? theme.newUI.defaultColors.primary
      : theme.newUI.variationColors?.light5};
  color: ${({ theme, $active }) =>
    $active ? theme.colors.white : theme.newUI.defaultColors.text};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const $StepProgressLabel = styled.div<{ $active?: boolean }>`
  font-weight: ${({ theme, $active = false }) =>
    $active ? theme.fonts.weight.mediumBold : theme.fonts.weight.normal};
  font-size: ${({ theme }) => theme.fonts.size.megaSmall};
  line-height: ${rem(20)};
  margin-top: ${rem(9)};
  color: ${({ theme, $active = false }) =>
    $active
      ? theme.newUI.variationColors?.dark1
      : theme.newUI.defaultColors.primary};
  width: max-content;
`;

export const $StepProgressSeparator = styled.div<{ $isAfterActive?: boolean }>`
  display: flex;
  flex: 1;
  height: 2rem;
  min-width: 50px;
  align-self: flex-start;
  align-items: center;
  &:after {
    content: '';
    height: 2px;
    width: 100%;
    background-color: ${({ theme, $isAfterActive = false }) =>
      $isAfterActive
        ? theme.newUI.variationColors?.light5
        : theme.newUI.defaultColors.primary};
    border-radius: 999px;
  }

  &:last-child {
    display: none;
  }
`;
