/* eslint-disable max-len */
import React, { FunctionComponent } from 'react';

import Icon from './Icon';
import { IconPropsInterface } from './Icon.types';

const ReversioIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
}) => (
  <Icon width="102px" height="20px" viewBox="0 0 102 20" color={color}>
    <path
      d="M94.3208 6.38428C95.5807 6.38428 96.7299 6.69072 97.7684 7.30362C98.8069 7.89949 99.6411 8.71668 100.271 9.7552C100.918 10.7767 101.241 11.9259 101.241 13.2027C101.241 14.4796 100.918 15.6288 100.271 16.6503C99.6411 17.6717 98.8069 18.4889 97.7684 19.1018C96.7299 19.6977 95.5807 19.9956 94.3208 19.9956C93.078 19.9956 91.9289 19.6977 90.8733 19.1018C89.8348 18.4889 89.0006 17.6717 88.3707 16.6503C87.7407 15.6288 87.4258 14.4796 87.4258 13.2027C87.4258 11.9259 87.7407 10.7767 88.3707 9.7552C89.0006 8.71668 89.8348 7.89949 90.8733 7.30362C91.9289 6.69072 93.078 6.38428 94.3208 6.38428ZM94.3208 9.52536C93.708 9.52536 93.1376 9.67007 92.6098 9.95949C92.0821 10.2489 91.6565 10.666 91.333 11.2108C91.0265 11.7556 90.8733 12.4196 90.8733 13.2027C90.8733 13.9859 91.0265 14.6498 91.333 15.1946C91.6565 15.7394 92.0821 16.1565 92.6098 16.446C93.1376 16.7184 93.708 16.8546 94.3208 16.8546C94.9337 16.8546 95.5041 16.7184 96.0318 16.446C96.5596 16.1565 96.9852 15.7394 97.3087 15.1946C97.6322 14.6498 97.7939 13.9859 97.7939 13.2027C97.7939 12.4196 97.6322 11.7556 97.3087 11.2108C96.9852 10.666 96.5596 10.2489 96.0318 9.95949C95.5041 9.67007 94.9337 9.52536 94.3208 9.52536Z"
      fill="currentColor"
    />
    <path
      d="M83.1283 0C83.7412 0 84.269 0.221323 84.7116 0.663969C85.1542 1.08959 85.3756 1.60033 85.3756 2.1962C85.3756 2.77505 85.1542 3.28579 84.7116 3.72844C84.269 4.17109 83.7412 4.39241 83.1283 4.39241C82.5154 4.39241 81.9876 4.17109 81.545 3.72844C81.1194 3.28579 80.9065 2.77505 80.9065 2.1962C80.9065 1.60033 81.1194 1.08959 81.545 0.663969C81.9876 0.221323 82.5154 0 83.1283 0ZM85.018 6.7163V19.6126H81.7748V9.47432H79.7063V6.7163H85.018Z"
      fill="currentColor"
    />
    <path
      d="M69.3839 6.38721C70.184 6.38721 70.9246 6.50638 71.6056 6.74473C72.2866 6.98308 72.8144 7.25547 73.1889 7.56192V10.4221C72.7803 10.1497 72.2781 9.88581 71.6822 9.63044C71.1034 9.37507 70.499 9.24738 69.8691 9.24738C69.2562 9.24738 68.771 9.38358 68.4134 9.65598C68.0559 9.92837 67.8772 10.2518 67.8772 10.6264C67.8772 10.8988 67.9793 11.1542 68.1836 11.3925C68.3879 11.6138 68.7795 11.8096 69.3583 11.9799L70.814 12.4651C72.7718 13.0439 73.7507 14.1761 73.7507 15.8615C73.7507 16.6617 73.5209 17.3767 73.0612 18.0067C72.6186 18.6196 72.0142 19.1048 71.2481 19.4623C70.499 19.8198 69.6648 19.9986 68.7454 19.9986C67.8772 19.9986 67.0344 19.8624 66.2172 19.59C65.4001 19.3176 64.7786 19.0196 64.353 18.6962V15.8615C64.6765 16.0829 65.0681 16.2957 65.5277 16.5C65.9874 16.7043 66.4641 16.8745 66.9578 17.0107C67.4515 17.1299 67.9027 17.1895 68.3113 17.1895C68.9582 17.1895 69.452 17.0788 69.7925 16.8575C70.15 16.6191 70.3287 16.2872 70.3287 15.8615C70.3287 15.5381 70.184 15.2827 69.8946 15.0954C69.6052 14.9081 69.1796 14.7209 68.6177 14.5336L67.0344 13.9718C66.047 13.6483 65.366 13.1886 64.9915 12.5928C64.6169 11.9969 64.4296 11.2904 64.4296 10.4732C64.4296 9.65598 64.6339 8.94945 65.0425 8.35358C65.4682 7.74068 66.0555 7.26399 66.8046 6.92349C67.5537 6.56597 68.4134 6.38721 69.3839 6.38721Z"
      fill="currentColor"
    />
    <path
      d="M62.9795 10.2689C62.4347 9.96242 61.8644 9.8092 61.2685 9.8092C60.5194 9.8092 59.898 10.0561 59.4043 10.5498C58.9106 11.0265 58.4764 11.6223 58.1019 12.3374V19.5389H54.8586V6.64258H58.1019V9.06862C58.5445 8.3025 59.0638 7.66407 59.6596 7.15333C60.2725 6.64258 60.962 6.38721 61.7282 6.38721C62.1878 6.38721 62.6049 6.46382 62.9795 6.61704V10.2689Z"
      fill="currentColor"
    />
    <path
      d="M46.603 6.38721C47.8628 6.38721 48.9439 6.65961 49.8462 7.2044C50.7655 7.74919 51.4636 8.5068 51.9403 9.47722C52.434 10.4306 52.6808 11.5372 52.6808 12.7971V14.1761H43.3342C43.5044 15.0443 43.9301 15.7424 44.6111 16.2701C45.2921 16.7979 46.1263 17.0618 47.1137 17.0618C48.0501 17.0618 48.9013 16.8745 49.6674 16.5C50.4336 16.1254 51.0465 15.7424 51.5061 15.3508L51.4806 18.3642C51.072 18.7217 50.4591 19.0877 49.6419 19.4623C48.8417 19.8198 47.9564 19.9986 46.986 19.9986C45.7092 19.9986 44.543 19.7091 43.4874 19.1303C42.4489 18.5515 41.6147 17.7598 40.9848 16.7553C40.3719 15.7509 40.0654 14.6017 40.0654 13.3078C40.0654 12.048 40.3463 10.8988 40.9082 9.86027C41.47 8.82176 42.2446 7.98754 43.232 7.35762C44.2195 6.71068 45.3431 6.38721 46.603 6.38721ZM46.5264 9.22184C45.7262 9.22184 45.0537 9.4687 44.5089 9.96242C43.9641 10.4561 43.5896 11.1201 43.3853 11.9543H49.5653C49.4972 11.035 49.1567 10.354 48.5438 9.91135C47.9309 9.45168 47.2584 9.22184 46.5264 9.22184Z"
      fill="currentColor"
    />
    <path
      d="M40.135 6.71924L34.0316 19.6156H31.9631L25.9363 6.71924H29.7158L32.959 14.7124L36.3555 6.71924H40.135Z"
      fill="currentColor"
    />
    <path
      d="M19.4172 6.28271C20.677 6.28271 21.7581 6.55511 22.6604 7.09991C23.5798 7.6447 24.2778 8.40231 24.7545 9.37272C25.2482 10.3261 25.495 11.4327 25.495 12.6926V14.0716H16.1484C16.3187 14.9398 16.7443 15.6379 17.4253 16.1656C18.1063 16.6934 18.9405 16.9573 19.9279 16.9573C20.8643 16.9573 21.7155 16.77 22.4816 16.3955C23.2478 16.0209 23.8607 15.6379 24.3203 15.2463L24.2948 18.2597C23.8862 18.6172 23.2733 18.9832 22.4561 19.3578C21.6559 19.7153 20.7707 19.8941 19.8002 19.8941C18.5234 19.8941 17.3572 19.6047 16.3016 19.0258C15.2631 18.447 14.4289 17.6553 13.799 16.6508C13.1861 15.6464 12.8796 14.4972 12.8796 13.2033C12.8796 11.9435 13.1605 10.7943 13.7224 9.75578C14.2842 8.71727 15.0588 7.88305 16.0463 7.25313C17.0337 6.60619 18.1573 6.28271 19.4172 6.28271ZM19.3406 9.11735C18.5404 9.11735 17.8679 9.36421 17.3231 9.85793C16.7783 10.3517 16.4038 11.0156 16.1995 11.8498H22.3795C22.3114 10.9305 21.9709 10.2495 21.358 9.80686C20.7451 9.34719 20.0726 9.11735 19.3406 9.11735Z"
      fill="currentColor"
    />
    <path
      d="M6.00425 4.92139C6.69591 4.92139 7.37286 5.0162 8.03509 5.20583C8.69733 5.39546 9.29334 5.68719 9.82312 6.08103C10.3676 6.47488 10.7944 6.97082 11.1034 7.56888C11.4272 8.15235 11.5891 8.85252 11.5891 9.66937C11.5891 10.53 11.3683 11.2739 10.9268 11.9011C10.5001 12.5138 9.93349 12.9952 9.22711 13.3452C8.52073 13.6807 7.75548 13.8777 6.93137 13.936L12.2513 19.5811H8.21169L3.06835 13.5859V19.5811H0V4.92139H6.00425ZM3.06835 7.7658V11.4635C3.42154 11.5802 3.81152 11.6751 4.23829 11.748C4.67978 11.8063 5.12127 11.8355 5.56276 11.8355C6.44573 11.8355 7.14476 11.6605 7.65983 11.3104C8.18961 10.9457 8.45451 10.4352 8.45451 9.77877C8.45451 9.06402 8.1749 8.55349 7.61568 8.24717C7.07118 7.92626 6.40159 7.7658 5.60691 7.7658H3.06835Z"
      fill="currentColor"
    />
    <path
      d="M77.4868 15.8115C78.0627 15.8115 78.5514 16.0209 78.9528 16.4398C79.3716 16.8412 79.5811 17.3298 79.5811 17.9058C79.5811 18.4817 79.3716 18.9791 78.9528 19.3979C78.5514 19.7993 78.0627 20 77.4868 20C76.9109 20 76.4135 19.7993 75.9947 19.3979C75.5933 18.9791 75.3926 18.4817 75.3926 17.9058C75.3926 17.3298 75.5933 16.8412 75.9947 16.4398C76.4135 16.0209 76.9109 15.8115 77.4868 15.8115Z"
      fill="#5369EB"
    />
  </Icon>
);

ReversioIcon.displayName = 'ReversioIcon';

export default ReversioIcon;
