import 'intl';
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'react-tippy/dist/tippy.css';

import App from './view/app/App';

if (![].at) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.at = function (pos) {
    return this.slice(pos, pos + 1)[0];
  };
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App />);
