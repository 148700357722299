import {
  CARRIER_CUSTOMER,
  OnboardingActivityTypes,
} from './OnboardingActivities.constant';
import { OnboardingActivitiesSubmitValues } from './OnboardingActivities.types';

const adaptOnboardingActivities = ({
  activities,
}: {
  activities: OnboardingActivitiesSubmitValues;
}): string[] => {
  const adaptedSelectedCustomers =
    activities.selectedCustomers.customers.reduce((acc: string[], customer) => {
      if (customer === OnboardingActivityTypes.BOTH_CUSTOMER) {
        return [
          ...acc,
          OnboardingActivityTypes.ENTERPRISE,
          OnboardingActivityTypes.PRIVATE,
        ];
      }

      return [...acc, customer];
    }, []);

  const adaptedSelectedSales = activities.selectedCustomers.sales.reduce(
    (acc: string[], sale) => {
      if (sale === OnboardingActivityTypes.ONLINE) {
        return [...acc, CARRIER_CUSTOMER.CHRONO, CARRIER_CUSTOMER.UPS];
      }

      if (sale === OnboardingActivityTypes.STORE) {
        return [
          ...acc,
          CARRIER_CUSTOMER.DROP_AT_STORE,
          CARRIER_CUSTOMER.IMMEDIATE_DROP_AT_STORE_DEPOSITE,
          CARRIER_CUSTOMER.PICKUP_STORE_DELIVERY,
        ];
      }

      return [
        ...acc,
        CARRIER_CUSTOMER.CHRONO,
        CARRIER_CUSTOMER.UPS,
        CARRIER_CUSTOMER.DROP_AT_STORE,
        CARRIER_CUSTOMER.IMMEDIATE_DROP_AT_STORE_DEPOSITE,
        CARRIER_CUSTOMER.PICKUP_STORE_DELIVERY,
      ];
    },
    [],
  );

  const adaptedSelectedMethods = activities.selectedReturns.methods.reduce(
    (acc: string[], method) => {
      if (method === OnboardingActivityTypes.STORE_RETURN) {
        return [
          ...acc,
          CARRIER_CUSTOMER.DROP_AT_STORE,
          CARRIER_CUSTOMER.IMMEDIATE_DROP_AT_STORE_DEPOSITE,
          CARRIER_CUSTOMER.CHRONO,
        ];
      }

      return [...acc, method];
    },
    [],
  );

  return activities.selectedDomains.concat(
    adaptedSelectedCustomers,
    adaptedSelectedSales,
    activities.selectedReturns.volumes,
    activities.selectedReturns.issues,
    adaptedSelectedMethods,
  );
};

export default adaptOnboardingActivities;
