import { defineMessages } from 'react-intl';

export default defineMessages({
  choiceInvoice: {
    id: 'view.authenticator.step.choiceInvoice.title',
    defaultMessage: 'choiceInvoice title',
  },
  choicePartnerStore: {
    id: 'view.authenticator.step.choicePartnerStore.title',
    defaultMessage: 'choicePartner title store',
  },
  choicePartnerProduct: {
    id: 'view.authenticator.step.choicePartnerProduct.title',
    defaultMessage: 'choicePartner title product',
  },
  choiceUniversModel: {
    id: 'view.authenticator.step.choiceUniversModel.title',
    defaultMessage: 'choiceUniversModel title',
  },
  choiceModelGeneric: {
    id: 'view.authenticator.step.choiceModelGeneric.title',
    defaultMessage: 'choiceModelGeneric title',
  },
  customerInformationName: {
    id: 'view.authenticator.step.customerInformationName.title',
    defaultMessage: 'customerInformationName title',
  },
  customerInformationAddress: {
    id: 'view.authenticator.step.customerInformationAddress.title',
    defaultMessage: 'customerInformationAddress title',
  },
  customerInvoiceInformation: {
    id: 'view.authenticator.step.customerInvoiceInformation.title',
    defaultMessage: 'customerInvoiceInformation title',
  },
});
