import React from 'react';
import { Col } from 'react-styled-flexboxgrid';

import STEP_CONFIG from 'authenticator/view/app/Routes/StepConfig';

import { $StepHeaderContainer } from './StepHeader.styles';
import StepProgress from './StepProgress/StepProgress';
import StepSubtitle from './StepSubtitle/StepSubtitle';
import StepTitle from './StepTitle/StepTitle';

interface StepHeaderProps {
  stepName?: STEP_CONFIG;
  isFinalStep?: boolean;
}

const StepHeader: React.FC<StepHeaderProps> = ({ stepName, isFinalStep }) => {
  if (!stepName) {
    return <>Error stepName</>;
  }

  return (
    <$StepHeaderContainer>
      <StepProgress stepName={stepName} />
      {!isFinalStep && (
        <Col xs={12}>
          <StepTitle stepName={stepName} />
          <StepSubtitle stepName={stepName} />
        </Col>
      )}
    </$StepHeaderContainer>
  );
};

StepHeader.displayName = 'StepHeader';

export default StepHeader;
